import * as Log from './log';
import * as Tabs from './tabs';
import * as ADM from './Administration';
import * as SDV from './sdv';
export default {
	Log,
	Tabs,
	ADM,
	SDV,
};
