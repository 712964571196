// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
const historys = createBrowserHistory({ basename: '/' });

const replace = path => {
	historys.replace(path);
};

export default {
	historys,
	replace,
};
