import React from 'react';
import Loadable from 'react-loadable';

const router = {
	PassPage: () =>
		Loadable({
			loader: () => import('../pages/login/password'),
			render(loaded, props) {
				const Component = loaded.default;
				return <Component {...props} />;
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
	DownloadPdf: () =>
		Loadable({
			loader: () => import('../pages/entryData/downloadPdf'),
			render(loaded, props) {
				const Component = loaded.default;
				return <Component {...props} />;
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
	Pass: () =>
		Loadable({
			loader: () => import('../pages/pass/pass'),
			render(loaded, props) {
				const Component = loaded.default;
				return <Component {...props} />;
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
	DataList: () =>
		Loadable({
			loader: () => import('../pages/dataList/dataList'),
			render(loaded, props) {
				const Component = loaded.default;
				return <Component {...props} />;
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),

	User: () =>
		Loadable({
			loader: () => import('../pages/user/user'),
			render(loaded, props) {
				const Component = loaded.default;
				return <Component {...props} />;
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
	Home: () =>
		Loadable({
			loader: () => import('../pages/home/home.js'),
			render(loaded, props) {
				const Component = loaded.default;
				return (
					<Component
						{...props} //userStore={Store.user} infoStore={Store.info}
					/>
				);
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
	Login: () =>
		Loadable({
			loader: () => import('../pages/login/LoginPage'),
			// loader: () => import('./../demo'),

			render(loaded, props) {
				const Component = loaded.default;
				return (
					<Component
						{...props} //userStore={Store.user} infoStore={Store.info}
					/>
				);
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
	Tabs: () =>
		Loadable({
			loader: () => import('../pages/entryData/Tabs'),
			render(loaded, props) {
				const Component = loaded.default;
				return (
					<Component
						{...props} //userStore={Store.user} infoStore={Store.info}
					/>
				);
			},
			loading() {
				return <div className="g-body">加载中...</div>;
			},
		}),
};

export default router;
