import { makeRequest, formatReq } from './config';
import { Sdv } from './url';

export const create_account = param => {
	return makeRequest(Sdv.create_account, param);
};

export const update_record = param => {
	return makeRequest(Sdv.update_record, param);
};

export const update_record_contact = param => {
	return makeRequest(Sdv.update_record_contact, param);
};

export const update_record_cost = param => {
	return makeRequest(Sdv.update_record_cost, param);
};

export const update_record_store_house = param => {
	return makeRequest(Sdv.update_record_store_house, param);
};

export const update_postoperative_info = param => {
	return makeRequest(Sdv.update_postoperative_info, param);
};

export const update_record_adverse_event = param => {
	return makeRequest(Sdv.update_record_adverse_event, param);
};
export const update_record_serious_adverse_event = param => {
	return makeRequest(Sdv.update_record_serious_adverse_event, param);
};

export const get_log_record = param => {
	return makeRequest(Sdv.get_log_record, param);
};

export const get_sdv_record = param => {
	return makeRequest(Sdv.get_sdv_record, param);
};

export const answer_question = param => {
	return makeRequest(Sdv.answer_question, param);
};

export const basic_info = param => {
	return makeRequest(Sdv.update_basic_info, param);
};

export const preoperative_examination = param => {
	return makeRequest(Sdv.update_preoperative_info, param);
};

export const operation_condition = param => {
	return makeRequest(Sdv.update_Surgery_info, param);
};

export const discharge_information = param => {
	return makeRequest(Sdv.update_ooh_info, param);
};

export const Summary_observation = param => {
	return makeRequest(Sdv.update_cos_info, param);
};

export const review_record = param => {
	return makeRequest(Sdv.review_record, param);
};
export const lock_record = param => {
	return makeRequest(Sdv.lock_record, param);
};

export const get_count = param => {
	return makeRequest(Sdv.get_count, param);
};
