import { observable, action } from 'mobx';
import Api from '../api/index';
import { message } from 'antd';

class Login {
	@observable user = '';
	@observable pass = '';
	@observable userName = '';
	@observable root = '';

	@action _login = async (user, parss) => {
		try {
			let param = {
				account: user,
				password: parss,
			};
			let res = await Api.Log.lgoins(param);

			if (res.messageCode === 1) {
				this.root = res.result.role;
				this.userName = res.result.userName;
				window.sessionStorage.setItem('status', true);
				window.sessionStorage.setItem('token', res.result.token);
				window.sessionStorage.setItem('userName', res.result.userName);
				window.sessionStorage.setItem('root', res.result.role);
				window.sessionStorage.setItem('hospital', res.result.hospital);
				window.sessionStorage.setItem('hospitalCode', res.result.hospitalCode);
				window._guider.History.default.historys.replace('/list');
				window.location.reload();
			}
		} catch (error) {
			message.success('用户名或密码有误，请重新输入或');
			console.log(error);
		}
	};

	@action _forget = async param => {
		try {
			let res = await Api.Log.forget_pwd(param);
			if (res.messageCode === 1) {
				message.success('发送成功注意查看');
			}
		} catch (error) {
			console.log(error);
		}
	};
}
export default new Login();
