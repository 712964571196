const Header = {
	'Content-Type': 'application/json;charset=UTF-8',
};

//用户登录
export const CURRICULM = {
	login: {
		url: '/account/login',
		method: 'post',
		headers: Header,
	},
	forget_pwd: {
		url: '/account/forget_pwd',
		method: 'post',
		headers: Header,
	},
	reset_psw: {
		url: '/account/reset_psw',
		method: 'post',
		headers: Header,
	},
};

//数据保存
export const INFOFORM = {
	add_record_info: {
		url: 'medical_record/add_record_info', //登入界面信息
		method: 'POST',
		headers: Header,
	},
	add_basic_info: {
		url: 'medical_record/add_basic_info', //基本信息
		method: 'POST',
		headers: Header,
	},
	add_preoperative_info: {
		url: 'medical_record/add_preoperative_info', //术前
		method: 'POST',
		headers: Header,
	},
	add_surgery_info: {
		url: 'medical_record/add_surgery_info', //手术情况
		method: 'POST',
		headers: Header,
	},
	add_ooh_info: {
		url: 'medical_record/add_ooh_info', //出院信息
		method: 'POST',
		headers: Header,
	},
	add_adverse_event_info: {
		url: 'medical_record/add_adverse_event_info', //不良事件
		method: 'POST',
		headers: Header,
	},
	complete_question: {
		url: '/sdv/complete_question', //完成质疑
		method: 'POST',
		headers: Header,
	},
	export_excel: {
		url: '/medical_record/export_excel', //exc
		method: 'get',
		responseType: 'blob',
	},
	add_cos_info: {
		url: 'medical_record/add_cos_info', //临床观察
		method: 'POST',
		headers: Header,
	},
	add_postoperative_info: {
		url: 'medical_record/add_postoperative_info', //临床观察
		method: 'POST',
		headers: Header,
	},
	//严重不良观察事件
	add_serious_adverse_event_info: {
		url: 'medical_record/add_serious_adverse_event_info', //临床观察
		method: 'POST',
		headers: Header,
	},

	//list
	get_records: {
		url: 'medical_record/get_records', //list病例
		method: 'get',
	},
	get_basic_info: {
		url: 'medical_record/get_basic_info', //基本信息
		method: 'get',
	},
	get_preoperative_info: {
		url: 'medical_record/get_preoperative_info', //手术前信息
		method: 'get',
	},
	get_ooh_info: {
		url: 'medical_record/get_ooh_info', //出院信息
		method: 'get',
	},
	get_postoperative_info: {
		url: 'medical_record/get_postoperative_info', //术后
		method: 'get',
	},
	get_adverse_event_info: {
		url: 'medical_record/get_adverse_event_info', //不良
		method: 'get',
	},
	get_cos_info: {
		url: 'medical_record/get_cos_info', //临床
		method: 'get',
	},
	get_surgery_info: {
		url: 'medical_record/get_surgery_info', //手术信息
		method: 'get',
	},
	delete_record: {
		url: 'medical_record/delete_record', //临床观察
		method: 'POST',
		headers: Header,
	},
	add_storage_test: {
		url: 'medical_record/add_storage_test', //临床观察
		method: 'POST',
		headers: Header,
	},

	add_group_investigatio: {
		url: '/medical_record/add_group_investigation', //入组标准排查
		method: 'POST',
		headers: Header,
	},
	add_case_login: {
		url: '/medical_record/add_case_login',
		method: 'POST',
		headers: Header,
	},

	add_case_basic: {
		url: '/medical_record/add_case_basic',
		method: 'POST',
		headers: Header,
	},
	update_case_basic: {
		url: '/medical_record/update_case_basic',
		method: 'POST',
		headers: Header,
	},

	update_case_pre_surgery: {
		url: '/medical_record/update_case_pre_surgery',
		method: 'POST',
		headers: Header,
	},
	add_case_pre_surgery: {
		url: '/medical_record/add_case_pre_surgery',
		method: 'POST',
		headers: Header,
	},
	add_case_surgery: {
		url: '/medical_record/add_case_surgery',
		method: 'POST',
		headers: Header,
	},
	update_case_surgery: {
		url: '/medical_record/update_case_surgery',
		method: 'POST',
		headers: Header,
	},
	add_case_discharged: {
		url: '/medical_record/add_case_discharged',
		method: 'POST',
		headers: Header,
	},
	update_case_discharged: {
		url: '/medical_record/update_case_discharged',
		method: 'POST',
		headers: Header,
	},
	add_case_post_surgery: {
		url: '/medical_record/add_case_post_surgery',
		method: 'POST',
		headers: Header,
	},
	update_case_post_surgery: {
		url: '/medical_record/update_case_post_surgery',
		method: 'POST',
		headers: Header,
	},
	add_case_adverse_event: {
		url: '/medical_record/add_case_adverse_event',
		method: 'POST',
		headers: Header,
	},
	update_case_adverse_event: {
		url: '/medical_record/update_case_adverse_event',
		method: 'POST',
		headers: Header,
	},
	add_case_serious_adverse_event: {
		url: '/medical_record/add_case_serious_adverse_event',
		method: 'POST',
		headers: Header,
	},
	update_case_serious_adverse_event: {
		url: '/medical_record/update_case_serious_adverse_event',
		method: 'POST',
		headers: Header,
	},
	add_case_clinical_observation: {
		url: '/medical_record/add_case_clinical_observation',
		method: 'POST',
		headers: Header,
	},
	update_case_clinical_observation: {
		url: '/medical_record/update_case_clinical_observation',
		method: 'POST',
		headers: Header,
	},
	// get_case_content: {
	// 	url: '/medical_record/get_case_content',
	// 	method: 'get',
	// },
	get_sdv_records: {
		url: '/sdv/get_sdv_records',
		method: 'get',
	},
	get_log_record: {
		url: '/medical_record/get_log_record',
		method: 'get',
	},
	freeze_case: {
		url: '/medical_record/freeze_case',
		method: 'post',
		headers: Header,
	},
	get_case_content: {
		url: '/medical_record/get_case_content',
		method: 'get',
	},
	export_pdf: {
		url: '/medical_record/export_pdf',
		method: 'get',
	},

	raise_question: {
		url: '/sdv/raise_question',
		method: 'post',
		headers: Header,
	},
	answer_question: {
		url: '/sdv/answer_question',
		method: 'post',
		headers: Header,
	},
};
//管理员
export const ADMIN = {
	create_account: {
		url: '/account/create_account',
		method: 'post',
		headers: Header,
	},
	update_psw: {
		url: '/account/update_psw',
		method: 'post',
		headers: Header,
	},
	getList: {
		url: '/hospital/get_hospital',
		method: 'get',
	},

	getUserList: {
		url: '/accounts',
		method: 'get',
	},
	deleteUser: {
		url: '/accounts',
		method: 'delete',
	},
	updateUser: {
		url: '/accounts',
		method: 'put',
	},
};
//提出质疑
export const Sdv = {
	create_account: {
		url: '/sdv/raise_question',
		method: 'post',
		headers: Header,
	},
	update_record: {
		url: '/medical_record/update_record',
		method: 'post',
		headers: Header,
	},
	update_record_contact: {
		url: '/medical_record/update_record_contact',
		method: 'post',
		headers: Header,
	},
	update_record_cost: {
		url: '/medical_record/update_record_cost',
		method: 'post',
		headers: Header,
	},
	update_record_store_house: {
		url: '/medical_record/update_record_store_house',
		method: 'post',
		headers: Header,
	},
	update_postoperative_info: {
		url: '/medical_record/update_postoperative_info',
		method: 'post',
		headers: Header,
	},
	update_record_adverse_event: {
		url: '/medical_record/update_record_adverse_event',
		method: 'post',
		headers: Header,
	},
	update_record_serious_adverse_event: {
		url: '/medical_record/update_record_serious_adverse_event',
		method: 'post',
		headers: Header,
	},
	get_log_record: {
		url: '/medical_record/get_log_record',
		method: 'get',
	},
	get_sdv_record: {
		url: '/medical_record/get_sdv_record',
		method: 'get',
	},
	answer_question: {
		url: '/sdv/answer_question',
		method: 'post',
		headers: Header,
	},
	update_basic_info: {
		url: '/medical_record/update_basic_info',
		method: 'post',
		headers: Header,
	},
	update_preoperative_info: {
		url: '/medical_record/update_preoperative_info',
		method: 'post',
		headers: Header,
	},
	update_Surgery_info: {
		url: '/medical_record/update_surgery_info',
		method: 'post',
		headers: Header,
	},
	update_ooh_info: {
		url: '/medical_record/update_ooh_info',
		method: 'post',
		headers: Header,
	},
	update_cos_info: {
		url: '/medical_record/update_cos_info',
		method: 'post',
		headers: Header,
	},
	review_record: {
		//原来锁定
		url: '/medical_record/review_record',
		method: 'post',
		headers: Header,
	},
	lock_record: {
		url: '/medical_record/lock_record',
		method: 'post',
		headers: Header,
	},
	get_count: {
		url: '/sdv/get_count',
		method: 'get',
	},
};
