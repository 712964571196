import { action, observable } from 'mobx';
import Api from '../api/index';
import { message, Modal } from 'antd';
import { hospitalListData } from '../utils/hospitalListData';
const { confirm } = Modal;

class Users {
	constructor() {
		// if (this.codeData.length >= 5) {
		// 	this.getCodeCentent();
		// }
	}
	@observable codeData = [
		{ type: 'Input', param: 'account', name: '账号', isSH: true, key: 1 },
		{
			type: 'Input',
			param: 'name',
			name: '姓名',
			isSH: true,
			key: 2,
		},
		{ type: 'Input', param: 'contact', name: '联系方式', isSH: true, key: 3 },
		{ type: 'Input', param: 'email', name: '邮箱', isSH: true, key: 4 },
		{
			type: 'Input',
			param: 'password',
			name: '密码',
			isSH: true,
			key: 5,
			pas: 'password',
		},
		// { type: 'Input', param: 'name', name: '录入员姓名', isSH: true, key: 6 },
		{
			type: 'Select',
			param: 'affiliationCode',
			name: '中心名称',
			isSH: true,
			key: 8,
			childData: hospitalListData,
		},
		{
			type: 'Select',
			param: 'role',
			name: '权限',
			isSH: true,
			key: 7,
			childData: [
				{ name: '研究者/录入员', val: 'ROLE_RESEARCHER' },
				{ name: '监察员', val: 'ROLE_WATCHER' },
				{ name: '管理员', val: 'ROLE_ADMIN' },
			],
		},
	];
	@observable codeCentent = null;

	@observable listData = [];

	@observable visible = false;
	//获取中心编码
	// @action getCodeCentent = async () => {
	// 	try {
	// 		let res = await Api.ADM.getList();
	// 		if (res.messageCode === 1) {
	// 			let data = [];
	// 			res.result.forEach(e => {
	// 				data.push({
	// 					val: e.hospitalId,
	// 					name: e.hospitalName,
	// 				});
	// 			});
	// 			this.codeCentent = data;
	// 			let obj = {};
	// 			obj['param'] = 'affiliationCode';
	// 			obj['name'] = '中心编号';
	// 			obj['type'] = 'Select';
	// 			obj['isSH'] = true;
	// 			obj['key'] = 8;
	// 			obj['childData'] = data;
	// 			this.codeData.push(obj);
	// 		}
	// 		// console.log(res);
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };
	//修改密码
	@action update_psw = async ref => {
		ref.current.validateFields(async (err, val) => {
			if (!err) {
				let res = await Api.ADM.update_psw(val);
				if (!this.msg(res, '修改成功')) return false;
			}
		});
	};
	//新增账号 _addUser
	@action addOrUpdateUser = (ref, name) => {
		try {
			ref.current.validateFields(async (err, val) => {
				if (!err) {
					if (name === '新增用户') {
						let res = await Api.ADM.create_account(val);
						this.msg(res, '添加成功');
						this.visible = false;
						this.getUserTable();

					} else {
						val.enable = 1;
						let res = await Api.ADM.updateUser(val);
						if (res && res.messageCode === 0) {
							await this.getUserTable();
							this.visible = false;
							message.success('修改成功');
							this.getUserTable();
						}
					}
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	//全部验证返回
	@action _AllVerification = ref => {
		ref.validateFields();
		let errors = ref.getFieldsError();
		if (Object.keys(errors).every(key => errors[key] === undefined)) {
			return ref.getFieldsValue();
		}
		return null;
	};
	//消息返回
	@action msg = (res, name) => {
		try {
			if (res.messageCode === 0) {
				message.success(res.msg);
				return false;
			} else if (res.messageCode === 1) {
				message.success(name);
				return true;
			} else {
				message.error(res.msg);
				return false;
			}
		} catch (error) {
			console.log(error);
		}
	};

	// get user Table
	@action getUserTable = async () => {
		let res = await Api.ADM.getUserList();
		if (res.messageCode === 1) {
			this.listData = res.result;
		}
	};

	@action deleteUser = obj => {
		confirm({
			title: `确定删除${obj.account}`,

			onOk: async () => {
				try {
					await Api.ADM.deleteUser({ account: obj.account });
					await this.getUserTable();
				} catch (err) {
					console.log(err);
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};
}
export default new Users();
