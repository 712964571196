import axios from 'axios';
axios.defaults.withCredentials = true;
import { message } from 'antd';
export const instance = axios.create({
	baseURL: '/api',
	timeout: 30000,
	headers: {
		'Content-Type': 'application/json',
	},
});
if (window.sessionStorage.getItem('token')) {
	instance.interceptors.request.use(res => {
		res.headers.common['Authorization'] =
			window.sessionStorage.getItem('token') || '';
		return res;
	});
}

export function failRequest(error, defaultValue) {
	let errMessage;
	console.log(error);
	if (error && error.data) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		if (error.data.status === 404) {
			errMessage = {
				message: '长时间未操作,请重新登录!',
				error: error.data.msg,
			};
			// window._guider.Store.user.updateStatus(false, ''); // 退出登录,自动跳转到登录页
		}
		if (error.data.status === 401) {
			message.error('账号密码错误');
		} else {
			errMessage = {
				message: '请求失败,系统报错:',
				error: error.data.msg,
			};
		}
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		errMessage = {
			message: '网络错误,无法连接',
			error: error.request,
		};
	} else {
		// Something happened in setting up the request that triggered an Error
		errMessage = {
			message: error.message ? error.message : '请求参数错误:',
			error: error.message,
		};
	}

	return defaultValue ? defaultValue : errMessage;
}

export function makeRequest(requestData, data) {
	const config = {
		...requestData,
	};

	if (data) {
		if (config.method === 'get') {
			config.params = data;
		} else {
			config.data = data;
		}
	}
	return instance(config)
		.then(res => {
			if (res.data.messageCode === 0) {
				return res.data;
			}
			if (res.data.messageCode === 1) {
				return res.data;
			} else if (res.data.messageCode === 500) {
				return res.data;
			} else {
				return res.data;
				// throw new Error(res.data.msg ? res.data.msg : 'fetch 获取数据错误!');
			}
		})
		.catch(error => {
			console.log(error);
			// return failRequest(error);
		});
}

export function formatReq(params) {
	const data = new URLSearchParams();

	Object.keys(params).forEach(key => {
		data.append(key, params[key]);
	});

	return data;
}
