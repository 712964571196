import { makeRequest } from './config';
import { INFOFORM } from './url';

export const add_record_info = param => {
	return makeRequest(INFOFORM.add_record_info, param);
};

export const add_basic_info = param => {
	return makeRequest(INFOFORM.add_basic_info, param);
};

export const add_preoperative_info = param => {
	return makeRequest(INFOFORM.add_preoperative_info, param);
};

export const add_surgery_info = param => {
	return makeRequest(INFOFORM.add_surgery_info, param);
};

export const add_ooh_info = param => {
	return makeRequest(INFOFORM.add_ooh_info, param);
};

export const add_adverse_event_info = param => {
	return makeRequest(INFOFORM.add_adverse_event_info, param);
};

export const complete_question = param => {
	return makeRequest(INFOFORM.complete_question, param);
};

export const export_excel = param => {
	return makeRequest(INFOFORM.export_excel, param);
};

export const add_cos_info = param => {
	return makeRequest(INFOFORM.add_cos_info, param);
};

export const add_postoperative_info = param => {
	return makeRequest(INFOFORM.add_postoperative_info, param);
};

//list Get
export const get_records = param => {
	return makeRequest(INFOFORM.get_records, param);
};

export const get_basic_info = param => {
	return makeRequest(INFOFORM.get_basic_info, param);
};

export const get_preoperative_info = param => {
	return makeRequest(INFOFORM.get_preoperative_info, param);
};

export const get_ooh_info = param => {
	return makeRequest(INFOFORM.get_ooh_info, param);
};

export const get_postoperative_info = param => {
	return makeRequest(INFOFORM.get_postoperative_info, param);
};

export const get_adverse_event_info = param => {
	return makeRequest(INFOFORM.get_adverse_event_info, param);
};

export const get_cos_info = param => {
	return makeRequest(INFOFORM.get_cos_info, param);
};

export const get_surgery_info = param => {
	return makeRequest(INFOFORM.get_surgery_info, param);
};

export const add_serious_adverse_event_info = param => {
	return makeRequest(INFOFORM.add_serious_adverse_event_info, param);
};

export const delete_record = param => {
	return makeRequest(INFOFORM.delete_record, param);
};

export const add_storage_test = param => {
	return makeRequest(INFOFORM.add_storage_test, param);
};

//入组标准
export const add_group_investigatio = param => {
	return makeRequest(INFOFORM.add_group_investigatio, param);
};

export const add_case_login = param => {
	return makeRequest(INFOFORM.add_case_login, param);
};

export const add_case_basic = param => {
	return makeRequest(INFOFORM.add_case_basic, param);
};

export const update_case_basic = param => {
	return makeRequest(INFOFORM.update_case_basic, param);
};

export const update_case_pre_surgery = param => {
	return makeRequest(INFOFORM.update_case_pre_surgery, param);
};
export const add_case_pre_surgery = param => {
	return makeRequest(INFOFORM.add_case_pre_surgery, param);
};

export const add_case_surgery = param => {
	return makeRequest(INFOFORM.add_case_surgery, param);
};

export const update_case_surgery = param => {
	return makeRequest(INFOFORM.update_case_surgery, param);
};
export const add_case_discharged = param => {
	return makeRequest(INFOFORM.add_case_discharged, param);
};
export const update_case_discharged = param => {
	return makeRequest(INFOFORM.update_case_discharged, param);
};
export const add_case_post_surgery = param => {
	return makeRequest(INFOFORM.add_case_post_surgery, param);
};
export const update_case_post_surgery = param => {
	return makeRequest(INFOFORM.update_case_post_surgery, param);
};
export const add_case_adverse_event = param => {
	return makeRequest(INFOFORM.add_case_adverse_event, param);
};
export const update_case_adverse_event = param => {
	return makeRequest(INFOFORM.update_case_adverse_event, param);
};

export const add_case_serious_adverse_event = param => {
	return makeRequest(INFOFORM.add_case_serious_adverse_event, param);
};
export const update_case_serious_adverse_event = param => {
	return makeRequest(INFOFORM.update_case_serious_adverse_event, param);
};

export const add_case_clinical_observation = param => {
	return makeRequest(INFOFORM.add_case_clinical_observation, param);
};

export const update_case_clinical_observation = param => {
	return makeRequest(INFOFORM.update_case_clinical_observation, param);
};

export const get_case_content = param => {
	return makeRequest(INFOFORM.get_case_content, param);
};

export const get_sdv_records = param => {
	return makeRequest(INFOFORM.get_sdv_records, param);
};


export const raise_question = param => {
	return makeRequest(INFOFORM.raise_question, param);
};

export const answer_question = param => {
	return makeRequest(INFOFORM.answer_question, param);
};


export const get_log_record = param => {
	return makeRequest(INFOFORM.get_log_record, param);
};

export const freeze_case = param => {
	return makeRequest(INFOFORM.freeze_case, param);
};

export const export_pdf = param => {
	return makeRequest(INFOFORM.export_pdf, param);
};

//
// export const get_case_content = param => {
// 	return makeRequest(INFOFORM.get_case_content, param);
// };
