import { action, observable } from 'mobx';
import Api from '../api/index';

class Tabs {
	@observable dataList = []; //获取并列列表~~~~
	@observable page = 1;
	@observable total = null;

	@action dowExc = async () => {
		let res = await Api.Tabs.export_excel();
		this._DownloadData(res, '病例数据.xls');
	};
	_DownloadData = (res, name) => {
		let url = window.URL.createObjectURL(new Blob([res]), {
			type: 'application/octet-stream',
		});
		let link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', name);
		document.body.appendChild(link);
		link.click();
	};
	//病列表
	@action _bDataList = async (page, hospitalCode) => {
		try {
			let param = {
				pageSize: 10,
				page: page,
				hospitalCode: hospitalCode,
				// testeeNumber:testeeNumber
				// hospitalCode:hospitalCode,
			};
			let res = await Api.Tabs.get_records(param);

			if (res.messageCode === 1) {
				// console.log(res.result);
				this.page = res.result.pageNum;
				this.total = res.result.totalPage * 10;
				this.dataList = _formatData(res.result.list);
			}
		} catch (error) {
			console.log(error);
		}
	};
}
export default new Tabs();

const _formatData = res => {
	return res.map(item => {
		return { key: item.medicalRecordId, ...item };
	});
};
