import { makeRequest, formatReq } from './config';
import { CURRICULM } from './url';

export const lgoins = param => {
	return makeRequest(CURRICULM.login, param);
};

export const forget_pwd = param => {
	return makeRequest(CURRICULM.forget_pwd, param);
};

export const reset_psw = param => {
	return makeRequest(CURRICULM.reset_psw, param);
};
