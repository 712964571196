//项目介绍在README.md中
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './router/index';
import * as serviceWorker from './serviceWorker';
import Store from './store/index';
import Api from './api/index';
import { AppContainer } from 'react-hot-loader';
import { ConfigProvider } from 'antd';
import { Provider } from 'mobx-react';
import * as History from './utils/history';
// import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

// console.log(moment('2019-11-27T16:00:00.000Z').format('YYYY-MM-DD HH:mm:ss'));
// console.log(moment('2019-11-27T00:00:00.000+0800').add(1, 'd').format('YYYY-MM-DD HH:mm:ss'));
// console.log(moment('2019-11-27T16:00:00.000Z').isSame('2019-11-27T00:00:00.000+0800')); //fasle
// console.log(moment('2019-11-27T16:00:00.000Z').isSame('2019-11-28T00:00:00.000+0800')); //true

window._guider = {
	History,
	Api,
};

// 是热更新
const render = Component => {
	ReactDOM.render(
		<AppContainer>
			<Provider {...Store}>
				<ConfigProvider locale={zhCN}>
					<App />
				</ConfigProvider>
			</Provider>
		</AppContainer>,
		document.getElementById('root')
	);
};
render(App);
if (module.hot) {
	module.hot.accept('./router/index', () => {
		const NextApp = require('./router/index').default;
		render(NextApp);
	});
}

serviceWorker.unregister();
