import Tabss from './tabs';
import Log from './login';
import Users from './users';
import Group from './addEntryData/groupInvestigationStore';

export default {
	Tabss,
	Log,
	Users,
	Group,
};
