import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import History from '../utils/history';
import router from './config';
import Header from '../pages/header/header';
import RedirectLogin from './../component/RedirectLogin';

const App = () => {
	const history = History.historys;
	const loginPage = router.Login();
	const passPage = router.PassPage();
	const homePage = router.Home();
	const DownloadPdf = router.DownloadPdf();

	return (
		<div className="app">
			<Router history={history}>
				<Switch>
					<Route exact path="/Login" component={loginPage} />
					<Route exact path="/download/:id" component={DownloadPdf} />
					<Route exact path="/PassPage/:user?" component={passPage} />
					<RedirectLogin>
						<Header />
						<Route exact path="/" component={homePage} />		<Route exact path="/" component={homePage} />
					</RedirectLogin>
				</Switch>
			</Router>
		</div>
	);
};

export default App;
