import React, { useEffect } from 'react';

const RedirectLogin = props => {
	useEffect(() => {
		let isLogin = window.sessionStorage.getItem('status');
		if (!isLogin) {
			window._guider.History.default.replace('/Login');
		}
	}, []);

	return props.children;
};

export default RedirectLogin;
