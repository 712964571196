import { makeRequest } from './config';
import { ADMIN } from './url';

export const create_account = param => {
	return makeRequest(ADMIN.create_account, param);
};

export const update_psw = param => {
	return makeRequest(ADMIN.update_psw, param);
};

export const getList = () => {
	return makeRequest(ADMIN.getList);
};

export const getUserList = () => {
	return makeRequest(ADMIN.getUserList);
};

export const deleteUser = param => {
	return makeRequest(ADMIN.deleteUser, param);
};

export const updateUser = param => {
	return makeRequest(ADMIN.updateUser, param);
};
