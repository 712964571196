import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink, Link, Route } from 'react-router-dom';
import './base_layout.css';
import Logo from '../../img/logo.png';
import { Icon } from 'antd';
import router from '../../router/config';
import { withRouter } from 'react-router-dom';
const heights = { minHeight: '100%' };
@inject('Log')
@observer
class Headers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: {
				selected: this.props.activeKey,
				data: [
					{
						label: '数据统计',
						icon: 'pie-chart',
						url: '/list',
						sub: [{ label: '统计信息' }],
						root: ['ROLE_ADMIN', 'ROLE_WATCHER', 'ROLE_RESEARCHER'],
					},
					{
						label: '用户管理',
						icon: 'user',
						url: '/user',
						sub: [{ label: '用户管理' }],
						root: ['ROLE_ADMIN'],
					},
					{
						label: '修改密码',
						icon: 'form',
						url: '/pass',
						sub: [{ label: '修改密码' }],
						root: ['ROLE_ADMIN', 'ROLE_WATCHER', 'ROLE_RESEARCHER'],
					},
					{
						label: '系统设置',
						icon: 'setting',
						url: '/setting',
						sub: [],
						root: ['ROLE_ADMIN', 'ROLE_WATCHER', 'ROLE_RESEARCHER'],
					},
					{
						label: '添加数据',
						icon: 'folder-add',
						url: '/tabs',
						sub: [{ label: '添加数据' }],
						root: ['ROLE_ADMIN', 'ROLE_RESEARCHER'],
					},
				],
			},
		};
	}
	componentDidMount() {}
	render() {
		const IconFont = Icon.createFromIconfontCN({
			scriptUrl: '//at.alicdn.com/t/font_1508244_vw9n3ubx1l.js', // 在iconfont.cn上生成
		});
		const { Log } = this.props;
		return (
			<div style={heights}>
				<div className="header">
					<div className="dashboard-logo">
						<img src={Logo} alt="logo" />
					</div>

					<div className="mini-nav">
						<span className="userName">
							欢迎 &nbsp;
							{Log.userName || window.sessionStorage.getItem('userName')}(
							{Log.root ||
								(window.sessionStorage.getItem('root') === 'ROLE_ADMIN' &&
									'管理员')}
							{Log.root ||
								(window.sessionStorage.getItem('root') === 'ROLE_WATCHER' &&
									'监察员')}
							{Log.root ||
								(window.sessionStorage.getItem('root') === 'ROLE_RESEARCHER' &&
									'研究者/录入员')}
							)
						</span>
						<ul>
							<li>
								<div>
									<IconFont
										onClick={this.SignQu}
										className="mini-nav-item"
										style={{ fontSize: '36px', color: '#fff' }}
										type="icondengchu"
									/>
								</div>
							</li>
							<li>
								<div>
									<Icon
										className="mini-nav-item"
										style={{ fontSize: '28px', color: '#fff' }}
										type="mail"
										theme="filled"
									/>
								</div>
							</li>
							<li>
								<div>
									<Icon
										className="mini-nav-item"
										style={{ fontSize: '28px', color: '#fff' }}
										type="setting"
										theme="filled"
									/>
								</div>
							</li>
						</ul>
					</div>
				</div>
				{/* router 子页面 */}
				<div className="container-fluid">
					<div className="dashboard-wrapper">
						<div className="top-nav">
							<ul>
								{this.state.menu.data.map((item, index) => {
									if (
										!item.root.includes(window.sessionStorage.getItem('root'))
									)
										return false;
									return (
										<li key={index}>
											<NavLink
												to={item.url}
												className="nav-item"
												activeClassName="selected">
												<Icon style={{ fontSize: '18px' }} type={item.icon} />
												<p>{item.label}</p>
											</NavLink>
										</li>
									);
								})}
							</ul>
						</div>
						<div className="sub-nav-wrapper">
							<div className="sub-nav">
								<ul>
									{this.state.menu.data.map(item => {
										if (item.url !== this.props.location.pathname) return null;
										return item.sub.map((subItem, subIndex) => {
											return (
												<li key={subIndex}>
													<Link
														to={''}
														className="sub-nav-item"
														style={{ color: '#ffffff' }}>
														{subItem.label}
													</Link>
												</li>
											);
										});
									})}
								</ul>
							</div>
						</div>
						<div className="content">
							<Route exact path="/tabs/:id?" component={router.Tabs()} />
							<Route exact path="/list" component={router.DataList()} />
							<Route exact path="/user" component={router.User()} />
							<Route exact path="/pass" component={router.Pass()} />
						</div>
					</div>
				</div>
				<div className="footer">
					<p>© 北京易得医数科技有限责任公司 2019-2022</p>
				</div>
			</div>
		);
	}
	SignQu = () => {
		window.sessionStorage.clear();
		window.location.reload();
	};
}

export default withRouter(Headers);
