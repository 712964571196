import { action, observable } from 'mobx';
import Api from '../../api/index';
import { message } from 'antd';
import { Modal } from 'antd';

const { confirm } = Modal;

class Group {
	@observable recordId = '';
	@observable groupFormCopyData = {
		'0': {
			aHospital: '',
			aHospitalCode: '',
			aTesteeNumber: '',
			aTesteeLetter: '',
			aModifiedby: '',
		},
		'2': {
			bAdData: '',
			bHeight: '',
		},
		'4': {
			dSurgeryDate: '',
		},
	};
	@observable admissionDate = null;
	@observable activeKey = '0';
	@observable mapKey2Api = {
		'0': Api.Tabs.add_group_investigatio,
		'1': Api.Tabs.add_case_login,
		'2': Api.Tabs.add_case_basic,
		'3': Api.Tabs.add_case_pre_surgery,
		'4': Api.Tabs.add_case_surgery,
		'5': Api.Tabs.add_case_discharged,
		'6': Api.Tabs.add_case_post_surgery,
		'7': Api.Tabs.add_case_adverse_event,
		'8': Api.Tabs.add_case_serious_adverse_event,
		'9': Api.Tabs.add_case_clinical_observation,
	};
	@observable mapKey2ModifyApi = {
		'0': Api.Tabs.update_case_basic,
		'1': Api.Tabs.update_case_basic,
		'2': Api.Tabs.update_case_basic,
		'3': Api.Tabs.update_case_pre_surgery,
		'4': Api.Tabs.update_case_surgery,
		'5': Api.Tabs.update_case_discharged,
		'6': Api.Tabs.update_case_post_surgery,
		'7': Api.Tabs.update_case_adverse_event,
		'8': Api.Tabs.update_case_serious_adverse_event,
		'9': Api.Tabs.update_case_clinical_observation,
	};
	@observable mapKey2GroupId = {
		'2': 'basic',
		'3': 'pre_surgery',
		'4': 'surgery',
		'5': 'discharged',
		'6': 'post_surgery',
		'7': 'adverse_event',
		'8': 'serious_adverse_event',
		'9': 'clinical_observation',
	};
	@observable lockedStatus = {
		'2': 'caseBasicStatus',
		'3': 'casePreSurgeryStatus',
		'4': 'caseSurgeryStatus',
		'5': 'caseDischargedStatus',
		'6': 'casePostSurgeryStatus',
		'7': 'caseAdverseEventStatus',
		'8': 'caseSeriousAdverseEventStatus',
		'9': 'caseClinicalObservationStatus',
	};
	@observable lockedQuestionStatusObj = {};
	@observable modelVisible = {
		visible: false,
		data: {},
	};
	@observable modelAnswer = {
		visible: false,
		data: {},
	};

	@observable sdvList = {};

	@observable logList = [];
	@observable operationLogData = {
		data: [],
		current: 1,
		totalPage: null,
	};

	@observable spinning = false;

	@observable
	@action
	addFormData = async (paramObj, key) => {
		if (!paramObj) return;
		this.copyData(paramObj, key);
		let res = await this.mapKey2Api[key](paramObj);
		if (res && res.messageCode === 1) {
			const { recordId, groupInvestigationEnable } = res.result;
			this._switchTab(key);
			message.success('添加成功');
			if (key === '0') {
				if (groupInvestigationEnable === 1) {
					this.recordId = recordId;
				} else {
					window._guider.History.default.historys.push('/list');
				}
			} else if (key === '9') {
				window._guider.History.default.historys.push('/list');
			}
		} else {
			if (!res) return;
			console.log(res);
			message.error(res.msg);
		}
	};
	_switchTab = key => {
		if (key === '9') return;
		this.activeKey = String(+key + 1);
	};

	@action modifyFormData = async (paramObj, key) => {
		if (!paramObj) return;
		let res = await this.mapKey2ModifyApi[key](paramObj);
		if (res && res.messageCode === 1) {
			message.success('保存成功');
			// TODO 暂时屏蔽
			// this.getSdv({ record_id: paramObj.recordId, key: key });
			// this.get_log_record({
			// 	page: 1,
			// 	record_id: paramObj.recordId,
			// 	key: key,
			// });
			return true;
		} else {
			message.success('保存失败');
		}
	};
	@action copyData = (paramObj, key) => {
		if (key === '0') {
			this.groupFormCopyData[key] = {
				aHospital: paramObj.centerName,
				aHospitalCode: paramObj.centerCode,
				// aTesteeNumber: paramObj.screeningNumber,
				// aTesteeLetter: paramObj.initials,
				// aModifiedby: paramObj.recorder,
			};
			return;
		}
		this.groupFormCopyData[key] = paramObj;
	};

	@action jump = status => {
		if (status === 'up' && this.activeKey !== '0') {
			this.activeKey = String(Number(this.activeKey) - 1);
		} else if (status === 'down' && this.activeKey !== '9') {
			this.activeKey = String(Number(this.activeKey) + 1);
		}
	};

	@action reqData = async ({ record_id, key }) => {
		let res = await Api.Tabs.get_case_content({
			record_id,
			group: this.mapKey2GroupId[key],
		});
		if (res && res.result && res.messageCode === 1) {
			this.recordId = res.result.recordId;
			return res.result;
		}
		// message.warning('暂无数据');
		return null;
	};

	@action getSdv = async ({ record_id, key }) => {
		let res = await Api.Tabs.get_sdv_records({
			record: record_id,
			group: this.mapKey2GroupId[key],
		});
		if (res && res.messageCode === 1 && res.result.length !== 0) {
			if (['6', '7', '8'].includes(key)) {
				this.sdvList = handleAnswerData(res.result);
			} else {
				this.sdvList = this.handleAnswerSingleData(res.result);
			}
			this.logList = res.result;
		} else {
			this.sdvList = {};
			this.logList = [];
		}
	};

	@action getList = async ({ record_id, key }) => {
		//待删除
		let res = await Api.Tabs.get_sdv_records({
			record: record_id,
			group: this.mapKey2GroupId[key],
			pageSize: 10,
			page: 1,
		});
		if (res && res.messageCode === 1 && res.result.length !== 0) {
			this.logList = res.result;
		} else {
			this.logList = [];
		}
	};

	@action get_log_record = async ({ page, record_id, key }) => {
		let res = await Api.Tabs.get_log_record({
			record: record_id,
			page: page,
			pageSize: 10,
			group: this.mapKey2GroupId[key],
		});
		if (res && res.messageCode === 1 && res.result.length !== 0) {
			this.operationLogData = {
				data: res.result.list,
				current: res.result.pageNum,
				totalPage: res.result.totalPage,
			};
		} else {
			this.operationLogData = {
				data: [],
				current: 1,
				totalPage: null,
			};
		}
	};
	@action question = async obj => {
		obj.group = this.mapKey2GroupId[obj.group];
		let res = await Api.Tabs.raise_question(obj);
		if (res.messageCode === 1) {
			this.modelVisible.visible = false;
			message.success('质疑成功');
			setTimeout(() => {
				this.getSdv({ record_id: this.recordId, key: this.activeKey });
				this.getFormStatusInfo({
					record_id: this.recordId,
					group: 'group_investigation',
				});
			}, 300);
		} else {
			message.error(res.msg);
		}
	};

	@action answer = async obj => {
		let res = await Api.Tabs.answer_question(obj);
		if (res.messageCode === 1) {
			this.modelAnswer.visible = false;
			message.success('回答质疑成功');

			setTimeout(() => {
				this.getSdv({ record_id: this.recordId, key: this.activeKey });
				this.getFormStatusInfo({
					record_id: this.recordId,
					group: 'group_investigation',
				});
			}, 300);
			return true;
			//调用刷新接口
		}
		return false;

	};

	@action getFormStatusInfo = async objParam => {
		let res = await Api.Tabs.get_case_content(objParam);
		if (res && res.messageCode) {
			this.lockedQuestionStatusObj = res.result;
		} else {
		}
	};

	@action lock = async objParam => {
		let res = await Api.Tabs.freeze_case(objParam);
		if (res && res.messageCode) {
			this.getFormStatusInfo({
				record_id: objParam.recordId,
				group: 'group_investigation',
			});
			message.success('锁定成功');
		} else {
			message.error('锁定失败');
		}
	};

	//检查阅读
	@action checkReading = info => {
		console.log(info);
		confirm({
			title: `回答质疑内容:《${info.sdvQuestion}》确定通过审核吗？`,
			onOk: async () => {
				console.log(this.recordId, this.activeKey);
				let res = await Api.Tabs.complete_question({ sdvId: info.sdvId });
				if (res.messageCode === 1) {
					message.success('审核成功');
					this.getSdv({ record_id: this.recordId, key: this.activeKey });
					this.getFormStatusInfo({
						record_id: this.recordId,
						group: 'group_investigation',
					});
				}
			},
			onCancel() {},
		});
	};

	handleAnswerSingleData = data => {
		const reduce = data.reduce((acc, curr) => {
			if (acc[curr.sdvFields]) {
				acc[curr.sdvFields].push(curr);
			} else {
				acc[curr.sdvFields] = [curr];
			}
			return acc;
		}, {});
		const result = Object.keys(reduce)
			.map(field => {
				return reduce[field][reduce[field].length - 1];
			})
			.reduce((acc, curr) => {
				acc[curr.sdvFields] = curr;
				return acc;
			}, {});
		return result;
	};
}
export default new Group();

/**
 {
 	 param: obj
 }

   =>

 {
   "5e7caa6c66c4527b9d250e96": {
     param: obj
   }
 }
 */
function handleAnswerData(data) {
	const reduce = data.reduce((acc, curr) => {
		if (acc[curr.sdvSubRecordId]) {
			if (acc[curr.sdvSubRecordId][curr.sdvFields]) {
				acc[curr.sdvSubRecordId][curr.sdvFields].push(curr);
			} else {
				acc[curr.sdvSubRecordId][curr.sdvFields] = [curr];
			}
		} else {
			acc[curr.sdvSubRecordId] = {
				[curr.sdvFields]: [curr],
			};
		}
		return acc;
	}, {});
	Object.keys(reduce).forEach(recordId => {
		const result = reduce[recordId];
		Object.keys(result).forEach(key => {
			const obj = result[key];
			result[key] = obj[obj.length - 1];
		});
	});
	return reduce;
}
